import axios from 'axios';
import { Toast } from "vant";
import "vant/lib/index.css";
import http from '@/api/http';
import router from '@/router/index'
// var base = "http://47.96.78.161:9997/display-model-api/displayModel/h5";
var base;
// base = "https://www.ustyle.cn/display-api/display-model-api/displayModel/h5"
// if (process.env.VUE_APP_ENV === 'dev') {
// base = '/api';
base = process.env.VUE_APP_BASE_URL;
// } else {
// base = process.env.VUE_APP_BASE_URL;
// }
// console.log(base)
const Config = {
    // 设置统一超时时间为十五秒
    TIMEOUT: 15000,
    baseURL: {
        dev: window.BASEURL_01,
        prod: ''
    }
}
const service = axios.create({
    // 这里可以放一下公用属性等。
    baseURL: base,
    // baseURL: 'http://47.96.78.161:9997', // 用于配置请求接口公用部分，请求时会自动拼接在你定义的url前面。
    timeout: 15000, // 请求超时时间
    headers: { // 请求头
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type, Content-Length, Authorization, Accept, X-Requested-With , yourHeaderFeild',
        'Access-Control-Allow-Methods': 'PUT,POST,GET,DELETE,OPTIONS',
        'X-Powered-By': '3.2.1',
    }
})
service.defaults.timeout = Config.TIMEOUT;
// 添加请求拦截器，在参数中加token
service.interceptors.request.use(
    config => {
        // 将token添加到每一个接口的参数中
        // 判断请求的类型：如果是post请求就把默认参数拼到data里面；如果是get请求就拼到params里面
        const user_info = JSON.parse(localStorage.getItem('h5_user_info'));
        if (localStorage.getItem('h5_user_info')) {
            // 注意：config.method 的判断值必须是小写的post和get
            if (config.method === 'post') {
                config.data = {
                    accessToken: user_info.accessToken,
                    alias: process.env.VUE_APP_ALIAS,
                    type: user_info.type,
                    email: JSON.parse(localStorage.getItem('h5_user_info')).userEmail ? JSON.parse(localStorage.getItem('h5_user_info')).userEmail : '',
                    phone: JSON.parse(localStorage.getItem('h5_user_info')).userPhone ? JSON.parse(localStorage.getItem('h5_user_info')).userPhone : '',
                    ...config.data
                }
            } else if (config.method === 'get') {
                config.params = {
                    accessToken: user_info.accessToken,
                    alias: process.env.VUE_APP_ALIAS,
                    type: user_info.type,
                    email: JSON.parse(localStorage.getItem('h5_user_info')).userEmail ? JSON.parse(localStorage.getItem('h5_user_info')).userEmail : '',
                    phone: JSON.parse(localStorage.getItem('h5_user_info')).userPhone ? JSON.parse(localStorage.getItem('h5_user_info')).userPhone : '',
                    ...config.params
                }
            }
        } else {
            if (config.method === 'post') {
                config.data = {
                    alias: process.env.VUE_APP_ALIAS,
                    type: 0,
                    ...config.data
                }
            } else if (config.method === 'get') {
                config.params = {
                    alias: process.env.VUE_APP_ALIAS,
                    type: 0,
                    ...config.params
                }
            }
        }
        return config
    },
    error => {
        return Promise.reject(error)
    })
service.interceptors.response.use(data => {
    //{data:{status:200,msg"",obj:{}},status:200}
    if (data.status && data.status == 200 && data.data.status == 500) {
        //业务逻辑错误
        return;
    }
    if (data.data.resultCode !== 200 && data.data.resultMsg) {

    }
    if (data.data.resultCode === 501) {
        Toast.fail("用户信息过期,请重新登录")
        if (window.localStorage.getItem("h5_user_info")) {
            console.log("执行刷新")
            window.localStorage.clear()
            router.replace({ path: '/', query: {} });
            // window.location.reload();
        }
    }
    if (data.data.msg) { }
    return data.data;
}, error => {
    console.log(error)

    if (error && error.response) {
        switch (error.response.status) {
            case 400:
                Toast.fail("错误请求(400)")
                break;
            case 401:
                Toast.fail("未授权，请重新登录(401)")
                break;
            case 403:
                Toast.fail("拒绝访问(403)")
                break;
            case 404:
                window.location.href = "/NotFound"
                Toast.fail("请求错误")
                break;
            case 405:
                Toast.fail("请求类型错误")
                break;
            case 406:
                Toast.fail("(请求不接受 406)无法使用请求的内容特性响应请求的网页")
                break;
            case 407:
                Toast.fail("需要代理授权")
                break;
            case 408:
                Toast.fail("请求超时")
                break;
            case 409:
                Toast.fail("服务器冲突")
                break;
            case 410:
                Toast.fail("服务器资源不存在")
                break;
            case 411:
                Toast.fail("需要有效长度 411")
                break;
            case 412:
                Toast.fail("服务器未满足前提条件")
                break;
            case 413:
                Toast.fail("（请求实体过大")
                break;
            case 414:
                Toast.fail("请求的URI过长，服务器无法处理")
                break;
            case 415:
                Toast.fail("请求的格式不受请求页面的支持")
                break;
            case 416:
                Toast.fail("（请求范围不符合要求")
                break;
            case 417:
                Toast.fail("（服务器未满足请求标头字段的要求")
                break;
            case 500:
                Toast.fail("Error: 500")
                break;
            case 501:
                Toast.fail("Error: 501")
                break;
            case 502:
                // error.message = '网络错误,服务器端无响应(502)'
                Toast.fail("网络错误,服务器端无响应")
                break;
            case 503:
                // error.message = '服务不可用(503)'
                Toast.fail("服务不可用")
                break;
            case 504:
                // error.message = '网关超时(504)'
                Toast.fail("网关超时")
                break;
            case 505:
                // error.message = '（HTTP版本不受支持 505)服务器不支持请求中所用的HTTP协议版本'
                Toast.fail("服务器不支持请求中所用的HTTP协议版本")
                break;
            default:
                error.message = `连接错误${error.response.status}`
        }
    } else {
        // 超时处理
        if (JSON.stringify(error).includes('timeout')) {
            // error.message("服务器响应超时")
            // return
            Toast("Connection timed out")
            return error.message = '连接超时'
            // return Message.error('服务器响应超时, 请检查网络')
        }
        // return error.message = '连接服务器失败'
        // return Message.error("连接服务器失败")
    }
    Toast.fail(error.message)
    /***** 处理结束 *****/
    return Promise.resolve(error.response)

    // return Promise.resolve(err);
})
export default service;